.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 12px;
    margin-top: -25px;
}

.metricWrapper {
    display: flex;
    flex-direction: row;
    margin-inline: 0.5rem;
}

.iconBackground {
    background-color: #f5f5f5;
    border-radius: 10px;
    margin-right: 0.5rem;
}

.icon {
    display: block;
    margin: auto;
    min-width: 36px;
    min-height: 36px;
}

.metric {
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
}