.navbar {
    background: white;
    border-bottom: 2px solid black;
}

.navbar a {
    color: var(--bs-btn-active-border-color);
    text-decoration: none;
}

.apps {
    cursor: pointer;
}

.appList a {
    color: var(--bs-btn-active-border-color);
    text-decoration: none;
    white-space: nowrap;
}

.popover {
    max-width: 512px;
}

.appContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.appContainer a {
    color: var(--bs-btn-active-border-color);
    text-decoration: none;
    cursor: pointer;
}

.app {
    
}

.app a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    cursor: pointer;
}
