
.time-line-box {
    padding-bottom: 1rem;
    width: 100%;
  }
  
  .time-line-box .timeline {
    list-style-type: none;
    display: flex;
    padding: 0;
    text-align: center;
  }
  
  .time-line-box .timestamp {
    margin: auto;
    margin-bottom: 5px;
    padding: 0px 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .time-line-box .status {
    padding: 0px 10px;
    display: flex;
    justify-content: center;
    border-top: 3px solid #455EFC;
    position: relative;
    transition: all 200ms ease-in;
  }
  .time-line-box .status span {
    padding-top: 8px;
  }
  .time-line-box .status span:before {
    content: '';
    width: 12px;
    height: 12px;
    background-color: #455EFC;
    border-radius: 12px;
    border: 2px solid #455EFC;
    position: absolute;
    left: 50%;
    top: 0%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transition: all 200ms ease-in;
  }
  
  .swiper-container {
    width: 95%; 
    margin: auto;
    overflow-y: auto;
  }
  .swiper-wrapper{
    direction: rtl;
    display: inline-flex;
    flex-direction: row;
    overflow-y:auto;
    justify-content: center;
  }

  .swiper-wrapper *{
    overflow-anchor: none;
  }

  #anchor {
    overflow-anchor: auto;
    height: 1px;
  }

  .swiper-container::-webkit-scrollbar-track{
     background:#a8a8a8b6;
  }
  .swiper-container::-webkit-scrollbar{
    height: 0.5rem;
  }
  .swiper-container::-webkit-scrollbar-thumb{
     background: #4F4F4F !important;
     cursor: pointer;
  }
  .swiper-slide {
    text-align: center;
    font-size: 12px;
    width: 200px;
    height: 100%;
    position: relative;
    cursor: pointer;
  }

.time-line-box  .status.active span:before {
    background-color: red;
  }