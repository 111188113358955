.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.metrics {
  background-color: var(--bs-gray-500);
  padding: 0.1rem;
  padding-inline: 0.3rem;
  border-radius: 0.2rem;
}

.app-name {
  color: var(--bs-body-color);
  text-decoration: none !important;
}

tr.active-row td {
  background-color: #f1f3ff !important;
}



/* Path: src/components/Navbar.css */
body {
  font-size: 12px;
  color: var(--textBlack);
  font-family: Roboto, sans-serif;
  letter-spacing: -.02em;
  --alertsBg: #3b3b3b;
  --blueBorders: #043e96;
  --disabled: #f1f1f1;
  --errorRed: #fee;
  --errorRedBorder: #e82127;
  --fontsPrimary: #000;
  --greenCandle: #219653;
  --highlighted: #a09cbb;
  --inactive: #8a8a8a;
  --lighterBg: #fff;
  --primaryColor: #043e96;
  --redCandle: #eb5757;
  --selected: #d8d8d8;
  --separator: #bdbdbd;
  --spBg: #f5f5f5;
  --textBlack: #191919;
  --lightOrange: #fbdad1;
  --lightPurple: #d5d1fe;
  --subtleText: #787878;
}

label.form-label {
  font-size: 14px;
  font-weight: 500;
  margin-right: 3px;
  white-space: nowrap;
}

select.form-select {
  padding: .5rem;
  border: 1px solid var(--lightPurple);
  transition: border .5s;
  border-radius: 4px;
  height: 2.25rem;
  background-color: #fff;
  font-size: 12px;
}

h1 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
  color: #333;
}

table.table tr th{
  border-top: none;
  border-bottom: 2px solid #e2eafe;
  font-size: 14px;
}

table.table {
  font-size: 12px;
  color: #212529;
}