.header {
    font-weight: bold;
    margin-bottom: 1rem;
}

.howTo {
    display: flex;
    flex-direction: column;

    span {
        margin-bottom: 1rem;
    }
}

.emptySlots {
    margin-block: 1rem;
}