.buyActive {
    td {
        background-color: lightgreen;
    }
}

.sellActive {
    td {
        background-color: lightcoral;
    }
}

.separator {
    border-top: 1px solid;
    border-bottom: 1px solid;
    display: flex;
    justify-content: center;
    padding: 2rem;
    margin-block: 2rem;
}