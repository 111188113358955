.tree,
.tree-node-group {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tree li {
  border-bottom: 1px solid gray;
}

.tree li[aria-expanded="true"] {
  border-bottom: none;
}

.tree-node {
  cursor: pointer;
}

.arrow--open {
  transform: rotate(90deg);
}

.tree-leaf-list-item:hover {
  background: rgb(164 205 254)
}

.tree-leaf-list-item--selected {
  background: rgb(164 205 254)
}

.tree-leaf-list-item:has(.filtered) {
  border-bottom: 0px;
}