@import '~bootstrap/scss/bootstrap';

$bs-pagination-active-bg: #043e96;

.avatar {
    vertical-align: middle;
    width: 100px;
    height: 100px;
    font-size: 25px;
    border-radius: 50%;
    text-align: center;
 }

 h2, h3, h4 {
    text-align: left;
 }

.nav-tabs .nav-link.active {
    color: #fff;
    background-color: #043e96;
}

.btn-primary {
    background-color: #043e96;
    border-color: #043e96;
}

.card {
   border-width: "var(--bs-border-width)";
   border-style: "solid";
   border-color: "var(--bs-border-color)";
   box-shadow: "5.45px 5.45px 10.9px rgba(174,174,192,.25), -2.63px -2.63px 10.9px #dfecff";
}

.label {
   text-align: left;
}

.flex {
   display: flex;
}

.initial-spinner {
   position: fixed;
   left: 0;
   top: 0;
   right: 0;
   bottom: 0;
   margin: auto;
}

.portfolio-border {
   border-width: var(--bs-border-width);
   border-style: solid;
   border-color: var(--bs-border-color);
   box-shadow: 0 0 15px #b1d0ff;

}