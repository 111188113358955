
  .border {
    box-shadow: 0 0 15px #b1d0ff;
    border-radius: 4px;
    padding: 1rem;
    width: 100%;
    // display: flex;
    // flex-direction: column;
    // justify-content: c;
  }
  